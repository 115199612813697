import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { PostCard } from './PostCard'
import { PostLoader } from '../Loading'
import styled from 'styled-components'
import { usePosts } from '../../../contexts/PostProvider'
import { useScrolledToBottom } from '../../../hooks/useScrolledToBottom'
import { environment } from '../../../environments'
import { authenticatedFetch } from '../../../lib/service'
import { FadeIn } from '../animations/FadeIn'
import { EmptyState } from '../EmptyState'
import {
  Common_AnythingToShare,
  Posts_EmptyStateDescription,
  Posts_EmptyStateTitle,
} from '../../../translations/messages'
import { FeedHeaderItem } from '../FeedHeaderItem'
import { useHistory } from 'react-router-dom'
import { NoAccess } from '../NoAccess'

type Props = {
  ownerId?: string
  canCreate: boolean
}

const FeedContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 37.5rem;
`

export const PostFeed: FunctionComponent<Props> = ({ ownerId, canCreate }) => {
  const history = useHistory()
  const {
    allPosts,
    setAllPosts,
    allPostsNextUrl,
    setAllPostsNextUrl,
    postsByOwner,
    setPostsByOwner,
    postsByOwnerNextUrl,
    setPostsByOwnerNextUrl,
  } = usePosts()
  const [initialStatus, setInitialStatus] = useState<
    'idle' | 'pending' | 'failure'
  >(allPosts ? 'idle' : 'pending')
  const [loadMoreStatus, setLoadMoreStatus] = useState<
    'idle' | 'pending' | 'failure'
  >('idle')

  useScrolledToBottom(() => loadMorePosts())

  useEffect(() => {
    const url = ownerId
      ? `${environment.API_URL}/posts/owner/${ownerId}`
      : `${environment.API_URL}/posts`

    authenticatedFetch(url)
      .then(({ results, next }) => {
        setInitialStatus('idle')
        if (ownerId) {
          setPostsByOwner((prevState: any) => ({
            ...prevState,
            [ownerId]: results,
          }))
          setPostsByOwnerNextUrl((prevState: any) => ({
            ...prevState,
            [ownerId]: next,
          }))
        } else {
          setAllPosts(results)
          setAllPostsNextUrl(next)
        }
      })
      .catch(() => setInitialStatus('failure'))
  }, [
    ownerId,
    setPostsByOwner,
    setAllPosts,
    setAllPostsNextUrl,
    setPostsByOwnerNextUrl,
  ])

  useEffect(() => {
    async function fetchPosts() {
      const url = ownerId
        ? `${environment.API_URL}/posts/owner/${ownerId}`
        : `${environment.API_URL}/posts`
      authenticatedFetch(url)
        .then(({ results, next }) => {
          if (ownerId) {
            setPostsByOwner((prevState: any) => ({
              ...prevState,
              [ownerId]: results,
            }))
            setPostsByOwnerNextUrl((prevState: any) => ({
              ...prevState,
              [ownerId]: next,
            }))
          } else {
            setAllPosts(results)
            setAllPostsNextUrl(next)
          }
        })
        .catch(() => {
          setInitialStatus('failure')
        })
    }
    const posts = ownerId ? postsByOwner[ownerId] : allPosts
    const videoIsProcessing = posts?.some(
      (x) =>
        x.videos &&
        x.videos.length > 0 &&
        x.videos.some((y) => y.status === 'Unknown' || y.status === 'Ingest')
    )
    if (videoIsProcessing) {
      const id = setInterval(fetchPosts, 2000)
      return () => clearInterval(id)
    }
  }, [
    allPosts,
    ownerId,
    setPostsByOwner,
    setPostsByOwnerNextUrl,
    setAllPosts,
    setAllPostsNextUrl,
    postsByOwner,
  ])
  const loadMorePosts = useCallback(() => {
    const url = ownerId ? postsByOwnerNextUrl[ownerId] : allPostsNextUrl

    if (url && loadMoreStatus !== 'pending') {
      setLoadMoreStatus('pending')

      authenticatedFetch(url)
        .then(({ results, next }) => {
          setLoadMoreStatus('idle')

          if (ownerId) {
            setPostsByOwner((prevState: any) => ({
              ...prevState,
              [ownerId]: [...prevState[ownerId], ...results],
            }))
            setPostsByOwnerNextUrl((prevState: any) => ({
              ...prevState,
              [ownerId]: next,
            }))
          } else {
            setAllPosts((prevState: any[]) => [...prevState, ...results])
            setAllPostsNextUrl(next)
          }
        })
        .catch(() => setLoadMoreStatus('failure'))
    }
  }, [
    ownerId,
    loadMoreStatus,
    allPostsNextUrl,
    setAllPosts,
    setAllPostsNextUrl,
    setPostsByOwner,
    postsByOwnerNextUrl,
    setPostsByOwnerNextUrl,
  ])

  if (initialStatus === 'pending') {
    return (
      <FeedContainer>
        <PostLoader />
      </FeedContainer>
    )
  }

  if (initialStatus === 'failure') {
    return (
      <FeedContainer>
        <NoAccess />
      </FeedContainer>
    )
  }

  const posts = ownerId ? postsByOwner[ownerId] : allPosts
  const createUrl = ownerId ? `/posts/create/${ownerId}` : `/posts/create`

  return (
    <FeedContainer>
      <FadeIn showOn={posts}>
        <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
          {canCreate && (
            <FeedHeaderItem
              label={Common_AnythingToShare}
              onClick={() => history.push(createUrl)}
            />
          )}

          {posts &&
            posts.length > 0 &&
            posts.map((post, i) => (
              <li key={`${post.id}-${i}`}>
                <PostCard data={post} />
              </li>
            ))}
        </ul>

        {posts && posts.length === 0 && (
          <EmptyState
            title={Posts_EmptyStateTitle}
            text={Posts_EmptyStateDescription}
          />
        )}
      </FadeIn>
      {loadMoreStatus === 'pending' && <PostLoader />}
    </FeedContainer>
  )
}
