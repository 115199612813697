import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { authenticatedFetch } from '../lib/service'
import { environment } from '../environments'
import { useHistory } from 'react-router-dom'
import { LanguageContext } from './LanguageContext'

type Auth = {
  authToken: string
  authUser?: {
    id: string
    first_name: string
    last_name: string
    image: any
    email: string
    is_employee: boolean
  }
  reqStatus: 'idle' | 'pending' | 'failure'
  permissions: { [key: string]: string[] }
  settings: any
  updateSettings: (settings: any) => Promise<void>
  handleStoreToken: (token: any) => void
}

export const AuthContext = createContext<Auth>({
  authToken: '',
  authUser: undefined,
  reqStatus: 'idle',
  permissions: {},
  settings: {},
  updateSettings: () => Promise.resolve(),
  handleStoreToken: () => {},
})

export const AuthProvider: FunctionComponent = ({ children }) => {
  const { selectLanguage } = useContext(LanguageContext)
  const history = useHistory()
  const [reqStatus, setReqStatus] = useState<'idle' | 'pending' | 'failure'>(
    'pending'
  )
  const [authToken, setAuthToken] = useState<any>(
    localStorage.getItem('jodacare-token')
  )
  const [authUser, setAuthUser] = useState<any | null>(null)
  const [permissions, setPermissions] = useState<{ [key: string]: string[] }>(
    {}
  )
  const [settings, setSettings] = useState<any>()

  useEffect(() => {
    // @ts-ignore
    const isNative = window?.ReactNativeWebView
    if (isNative) {
      // @ts-ignore
      const authToken = window._authToken
      // @ts-ignore
      if (window._forAuth === 'YES' && window._authToken) {
        // @ts-ignore
        localStorage.setItem('jodacare-token', window._authToken)
        // @ts-ignore
        setAuthToken(window._authToken)
        // @ts-ignore
        window?.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'AUTH_SUCCESS',
            payload: authToken,
          })
        )
      }
    }
  }, [history])

  useEffect(() => {
    if (authToken) {
      setReqStatus('pending')

      authenticatedFetch(`${environment.API_URL}/auth`)
        .then((response) => {
          setReqStatus('idle')
          setAuthUser(response)

          // if (!response.accepted_eula) {
          //   history.push('/eula')
          // }

          return Promise.all([
            authenticatedFetch(
              `${environment.API_URL}/users/${response.id}/permissions`
            ),
            authenticatedFetch(
              `${environment.API_URL}/users/${response.id}/settings`
            ),
          ]).then(([permissions, settings]) => {
            setPermissions(permissions)
            setSettings(settings)
            selectLanguage(settings.language)
          })
        })
        .catch(() => {
          setAuthUser(null)
          setAuthToken(null)
        })
    } else {
      setReqStatus('idle')
      setAuthUser(null)
      setAuthToken(null)
    }
  }, [authToken, history, selectLanguage])

  const updateSettings = (settings: any) => {
    setReqStatus('pending')

    return authenticatedFetch(
      `${environment.API_URL}/users/${authUser.id}/settings`,
      {
        method: 'PATCH',
      },
      settings
    )
      .then((response) => {
        setReqStatus('idle')
        setSettings(response)
      })
      .catch(() => {
        setReqStatus('failure')
      })
  }
  //Store token on state on successful authentication
  const handleStoreToken = (token: any) => {
    // @ts-ignore
    const isNative = window?.ReactNativeWebView
    if (isNative) {
      // @ts-ignore
      window?.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'AUTH_SUCCESS',
          payload: token,
        })
      )
    }
    setAuthToken(token)
  }

  return (
    <AuthContext.Provider
      value={{
        authToken,
        authUser,
        reqStatus,
        permissions,
        settings,
        updateSettings,
        handleStoreToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
